<template>
  <div class="online_platform">
    <dv-charts :key="key" :option="option"/>
  </div>
</template>

<script>
import moment from 'moment'
import dayjs from "dayjs";

export default {
  name: 'onlinePlatform',
  props: {
    contentData: {
      type: Object,
      default: () => {
        return []
      }
    },
    showHours: {
      type: Number
    }
  },
  data() {
    return {
      key: 1,
      option: {
        grid: {
          left: 40,
          top: 30,
          right: 0,
          bottom: 20
        },
        xAxis: {
          data: null,
          min: 0,
          axisLine: {
            style: {
              stroke: '#324D4D',
              lineDash: [5, 2]
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            style: {
              fontSize: 12,
              fill: '#CCDADC'
            },
            formatter: (item) => {
              if (this.showHours === 2) {
                return item.value
              }
              if (item.index % 3 === 0) {
                return item.value
              } else {
                return ''
              }
            }
          },
          splitLine: {
            stroke: 'red',
            lineWidth: 1
          }
        },
        yAxis: {
          name: null,
          nameTextStyle: {
            fill: '#CCDADC',
            fontSize: 12
          },
          data: 'value',
          min: 0,
          max: 100,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            style: {
              fontSize: 12,
              fill: '#CCDADC'
            }
          },
          splitLine: {
            style: {
              stroke: '#CCDADC',
              lineWidth: 1,
              lineDash: [5, 2]
            }
          }
        },
        series: [
          {
            data: null,
            type: 'line',
            xAxisIndex: 0,
            yAxisIndex: 0,
            linePoint: {
              show: false
            },
            lineStyle: {
              stroke: '#24FFFC',
              lineWidth: 3
            }
          }
        ]
      }
    }
  },
  mounted() {
    moment.locale('zh-cn')
    this.init()
  },
  watch: {
    'contentData': {
      handler: function (data) {
        if (this.showHours === 0) {
          this.init(data.dateData)
        } else if (this.showHours === 1) {
          this.init(data.hoursData)
        } else {
          this.init(data.monthData)
        }
      },
      deep: true,
      immediate: true
    },
    'showHours': {
      handler: function (val) {
        if (val === 0) {
          this.init(data.dateData)
        } else if (val === 1) {
          this.init(data.hoursData)
        } else {
          this.init(data.monthData)
        }
      },
      immediate: true
    }
  },
  methods: {
    init(data) {
      if (!data || data.length === 0) {
        return false
      }
      if (this.showHours === 1) {
        this.option.yAxis.name = '实时在线率(%)'
        this.option.xAxis.data = data.map(item => {
          return item.CalculateDate + '点'
        })
      } else if (this.showHours === 0) {
        this.option.yAxis.name = '每日在线率(%)'
        this.option.xAxis.data = data.map(item => {
          return moment(item.CalculateDate).format('MM-DD')
        })
      } else {
        this.option.yAxis.name = '每月在线率(%)'
        this.option.xAxis.data = data.map(item => {
          return dayjs(item.AcquitMonth).month() + '月'
        })
      }
      this.option.series[0].data = data.map(item => {
        return item.OnlinePercent
      })
      this.$nextTick(function () {
        this.key++
      })
    }
  }
}
</script>

<style lang="scss">
.online_platform {
  width: 100%;
  box-sizing: border-box;

  .dv-charts-container {
    height: 120px;
  }
}
</style>
