<template>
  <div class="wrapper">
    <div class="echarts" id="chartMap" ref="chartMap"></div>
    <div :class="[showMore ? 'large' : '', 'exceed_content']" :style="showMore? 'width:700px;margin-bottom:40px':''">
      <dec-block-title style="cursor: pointer" @click.native="showMore = !showMore">
        街道数据展示
        <span style="float:right">{{ showMore ? '点击关闭' : '点击展开' }}</span>
      </dec-block-title>
      <el-table
        ref="table"
        v-show="showMore"
        :data="sortOption.sortData"
        height="330"
        :key="tableKey"
        style="width: 100%"
        :cell-style="cellStyle"
        :header-cell-style="headerStyle"
        @header-click="clickHeader"
        empty-text='0'>
        <el-table-column
          prop="Name"
          label="街道名"
          align="center"
          width="70"
        >
        </el-table-column>
        <el-table-column
          prop="Number"
          label="监测点数"
          align="center"
          width="70">
        </el-table-column>
        <el-table-column
          prop="OnlineDeviceCount"
          label="在线数"
          align="center"
          width="60"
        >
        </el-table-column>
        <el-table-column
          prop="OnlinePercent"
          label="在线率"
          align="center"
          width="60"
        >
        </el-table-column>
        <el-table-column
          prop="NomalDeviceCount"
          label="达标数"
          align="center"
          width="60"
        >
        </el-table-column>
        <el-table-column
          prop="NomalPercent"
          label="达标率"
          align="center"
          width="60"
        >
        </el-table-column>
        <el-table-column
          prop="ExcessDeviceCount"
          label="超标数"
          align="center"
          width="60"
        >
        </el-table-column>
        <el-table-column
          prop="ExcessPercent"
          label="超标率"
          align="center"
          width="60"
        >
        </el-table-column>
        <el-table-column
          prop="empty"
          label="执法工单派遣总数"
          align="center"
          width="70"
        >
          <div>0</div>
        </el-table-column>
        <el-table-column
          prop="empty"
          label="已整改商户数目"
          align="center"
          width="70"
        >
          <div>0</div>
        </el-table-column>
        <el-table-column
          prop="empty"
          label="商户整改率"
          align="center"
          width="60"
        >
          <div>0</div>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import greenCircle from '@/asset/img/MapIcon/SmallGreen.png'
import redCircle from '@/asset/img/MapIcon/SmallRed.png'
import orangeCircle from '@/asset/img/MapIcon/ExceedOrange.png'
import decBlockTitle from '../decorations/blockTitle2'
import {defineLocale} from 'moment'

const iconSize = new AMap.Size(12, 12)
const largeIconSize = new AMap.Size(14, 14)
const greenCircleIcon = new AMap.Icon({
  size: iconSize,
  image: greenCircle,
  imageSize: iconSize
})

const redCircleIcon = new AMap.Icon({
  size: iconSize,
  image: redCircle,
  imageSize: iconSize
})

const orangeCircleIcon = new AMap.Icon({
  size: largeIconSize,
  image: orangeCircle,
  imageSize: largeIconSize
})

const infoWindow = new AMap.InfoWindow({
  isCustom: true,
  content: null,
  anchor: 'top-left',
  offset: new AMap.Pixel(16, -45),
  closeWhenClickMap: true
})

export default {
  name: 'dotMapDistrict',
  components: {
    decBlockTitle
  },
  props: {
    contentData: {
      type: Object,
      default: () => {
        return {
          areaId: null,
          code: ''
        }
      }
    },
    isShowExceed: {
      type: Boolean
    },
    isShowEmphasisDot: {
      type: Object
    },
    onlineDevice: {
      type: Object,
      default: () => {
        return {}
      }
    },
    sumData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    topList: {
      type: Object,
      default: () => {
        return {}
      }
    },
    boardList: {
      type: Object,
      default: () => {
        return {}
      }
    },
    countyLevelData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      myCharts: null,
      aliMap: null,
      map: {},
      parentJson: [],
      areaPath: [],
      geoJsonData: {},
      mapData: [],
      parentCode: ['100000'],
      config: {
        data: [],
        showValue: true,
        colors: ['#37a2da']
      },
      dotsCollection: [],
      key: 1,
      showMore: false,
      tableKey: 1,
      sortOption: {
        defaultData: [],
        sortData: [],
        order: 'default',
        name: null
      }
    }
  },
  mounted() {
    this.initData()
  },
  computed: {
    ...mapState(['topBoardData'])
  },
  methods: {
    initData() {
      this.getData()
      this.sortOption.sortData = this.sumData.streetData
      this.sortOption.defaultData = this.sumData.streetData
      this.initAliMap(this.contentData.code)
    },
    clickHeader(column, event) {
      this.sortExceedContent(column.property)
    },
    calcTableHeight() {
      this.$nextTick(() => {
        if (this.$refs.table) {
          this.tableHeight = this.$refs.table.$el.parentElement.offsetHeight;
        }
      })
    },
    sortExceedContent(name) {
      if (name !== this.sortOption.name) {
        this.sortOption.order = 'default'
      }
      this.sortOption.name = name
      switch (this.sortOption.order) {
        case 'desc':
          this.sortOption.order = 'asce'
          this.sortOption.sortData = [...this.sortOption.defaultData].sort(function (a, b) {
            return (a[name] - b[name])
          })
          break
        case 'asce':
          this.sortOption.order = 'default'
          this.sortOption.sortData = [...this.sortOption.defaultData]
          break
        case 'default':
          this.sortOption.order = 'desc'
          this.sortOption.sortData = [...this.sortOption.defaultData].sort(function (a, b) {
            return (b[name] - a[name])
          })
          break
      }

    },
    getTableColName(level) {
      switch (level) {
        case 'country':
          return '省/直辖市名'
        case 'province':
          return '市名'
        case 'city':
          return '区名'
      }
    },
    getDecBlockTitle(level) {
      switch (level) {
        case 'country':
          return '省级信息展示'
        case 'province':
          return '市级信息展示'
        case 'city':
          return '区级信息展示'
      }
    },
    initAliMap(areaCode) {
      const that = this
      that.aliMap = new AMap.Map('chartMap', {
        resizeEnable: true,
        mapStyle: 'amap://styles/db776e87dd712097ad645ad2fbf86a6b'
      })
      const polygon = new AMap.Polygon({
        strokeWeight: 1,
        path: null,
        fillOpacity: 0.3,
        fillColor: '#CCF3FF',
        strokeColor: '#CC66CC'
      })
      // done用于district.search异步操作
      const done = function () {
        // 地图自适应
        that.aliMap.add(polygon)
        that.aliMap.setFitView()
        that.drawAliMap()
      }
      let district
      AMap.plugin('AMap.DistrictSearch', function () {
        // 创建行政区查询对象
        district = new AMap.DistrictSearch({
          // 返回行政区边界坐标等具体信息
          extensions: 'all',
          // 设置查询行政区级别为 区
          level: 'district'
        })
      })
      district.search(areaCode, function (status, result) {
        // 获取区的边界信息
        polygon.setPath(result.districtList[0].boundaries)
        done()
      })
      console.log('aliMap done!')
    },
    drawAliMap() {
      const that = this
      const deviceDetail = this.onlineDevice.listDevice || []
      for (let i = 0; i < deviceDetail.length; i++) {
        const item = deviceDetail[i]
        const marker = new AMap.Marker({
          position: [item.Lng, item.Lat],
          zIndex: 1,
          icon: item.HealthCodeColor === 'Green' ? greenCircleIcon : redCircleIcon,
          extData: {
            Status: that.dotStatusFormatter(item.Status),
            HealthCodeColor: item.HealthCodeColor
          }
        })
        that.dotsCollection.push(marker)
        marker.on('click', function () {
          const healthStatusColor = item.HealthCodeColor === 'Green' ? '#78ff79' : '#FF001A'
          infoWindow.setContent(`
          <div style="border:1px solid #03608C;padding:8px 16px;background-color:#011015;opacity:0.8">
            <ul style="list-style:none;margin: 0;padding:0;font-size:15px;">
              <li>
                <p>
                  <span style="color:#B2F2F6;">店铺名称：</span>
                  <span style="color:#67e0e3;">${item.CustomerName}</span>
                </p>
              </li>
              <li>
                <div style="display:inline-block;color:#B2F2F6;">油烟健康码：</div>
                <div style="width:10px;height:10px;background-color:${healthStatusColor};display:inline-block;border-radius:50%;margin-right:5px"></div>
              </li>
              <li>
                <p>
                  <span style="color:#B2F2F6;">联系电话：</span>
                  <span style="color:#67e0e3;">${item.CustomerMobile}</span>
                </p>
              </li>
              <li>
                <h5 style="color:#B2F2F6;margin:5px 0 0;">监测点信息</h5>
              </li>
              <li>
                <p>
                  <span style="color:#B2F2F6;">监测点名称：</span>
                  <span style="color:#67e0e3;">${item.LocaleName}</span>
                </p>
              </li>
              <li>
                <p>
                  <span style="color:#B2F2F6;">菜系：</span>
                  <span style="color:#67e0e3;">${item.CuisineName}</span>
                </p>
              </li>
              <li>
                <p>
                  <span style="color:#B2F2F6;">地址：</span>
                  <span style="color:#67e0e3;">${item.Addr}</span>
                </p>
              </li>
              <li>
                <p>
                  <span style="color:#B2F2F6;">设备：</span>
                  <span style="color:#67e0e3;">${item.MN}</span>
                </p>
              </li>
            </ul>
          </div>
        `)
          infoWindow.open(that.aliMap, marker.getPosition())
        })
        marker.setMap(that.aliMap)
      }
    },
    getData() {
      const installData =
        this.countyLevelData.deviceInstalledYesterday || []
      const complianceData =
        this.countyLevelData.complianceYesterday || []
      const dataMap = complianceData.map((item) => {
        installData.map((vo) => {
          if (item.K.split('/').join('') === vo.K.split('/').join('')) {
            item.name = vo.N
            item.value = item.V
          }
        })
        return item
      })
      this.$set(this.config, 'data', dataMap)
      this.key++
    },
    boardDataChange(data) {
      this.$store.commit('setNewBoardData', {res: data, dataObj: this.contentData})
    },
    backTop() {
      this.$store.dispatch('popNewBoardData')
    },
    dotStatusFormatter(Status) {
      switch (Status) {
        case 'NORMAL':
          return 2
        case 'EXCESS':
          return 1
        default:
          return 3
      }
    },
    //绘制eltable表头风格
    headerStyle({row, column, rowIndex, columnIndex}) {
      let style = "color:#67e0e3;" +
        "background: #011d24;\n" +
        "border: solid 1px rgb(3, 96, 140);" +
        "height:30px;" +
        "font-size:12px;" +
        "font-weight: normal;"
      return style
    },
    //绘制eltable单元格风格
    cellStyle({row, column, rowIndex, columnIndex}) {
      let style = "color:#67e0e3;" +
        "background: #011d24;\n" +
        "border: solid 1px rgb(3, 96, 140);" +
        "height:4vh;" +
        "font-size:12px;" +
        ""
      return style
    }
  },
  watch: {
    isShowExceed: {
      handler: function (val) {
        if (this.dotsCollection) {
          if (val) {
            this.dotsCollection.forEach(marker => {
              const status = marker.getExtData().Status
              if (status === 'EXCESS') {
                marker.setIcon(orangeCircleIcon)
              } else {
                marker.hide()
              }
            })
          } else {
            this.dotsCollection.forEach(marker => {
              const healthCodeColor = marker.getExtData().HealthCodeColor
              marker.setIcon(healthCodeColor === 'Green' ? greenCircleIcon : redCircleIcon)
              marker.show()
            })
          }
        }
      }
    },
    isShowEmphasisDot: {
      handler: function (val, oldVal) {
        if (this.dotsCollection) {
          if (!val.show) {
            this.dotsCollection.forEach(marker => {
              const healthCodeColor = marker.getExtData().HealthCodeColor
              marker.setIcon(healthCodeColor === 'Green' ? greenCircleIcon : redCircleIcon)
              marker.show()
            })
            return
          }
          let func
          switch (val.type) {
            case 'online':
              func = function (t) {
                return t < 3
              }
              break
            case 'normal':
              func = function (t) {
                return t === 2
              }
              break
            case 'exceed':
              func = function (t) {
                return t === 1
              }
              this.dotsCollection.forEach(marker => {
                const status = marker.getExtData().Status
                if (status === 1)
                  console.log('1', marker.getExtData())
                if (func(status)) {
                  marker.setIcon(orangeCircleIcon)
                  marker.show()
                } else {
                  marker.hide()
                }
              })
              return
            default:
              return
          }
          this.dotsCollection.forEach(marker => {
            const status = marker.getExtData().Status
            if (status !== 2)
              console.log('2', marker.getExtData())
            if (func(status)) {
              marker.show()
            } else {
              marker.hide()
            }
          })
        }
      }
    },
    sumData: {
      handler: function () {
        this.initData()
      }
    },
    onlineDevice: {
      handler: function () {
        this.initData()
      }
    },
    topList: {
      handler: function () {
        this.initData()
      }
    },
    boardList: {
      handler: function () {
        this.initData()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.echarts {
  width: 100%;
  height: 100%;
  position: relative;
}

.large {
  width: 23vw;
  height: 330px;
}



.exceed_content {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 12px;
  color: #67e0e3;
  min-width: 12vw;
  background: #011d24;
  border: solid 1px rgb(3, 96, 140);

  .ul_head {
    margin: 0;
    padding: 0;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #67e0e3;
    }

    .li_item {
      display: flex;
      justify-content: space-around;
      height: 4vh;
      line-height: 4vh;

      span {
        text-align: center;
        display: inline-block;
      }

      .item1 {
        width: 40%;
      }

      .item2,
      .item3 {
        width: 30%;
      }

      .item-head {
        cursor: pointer;
      }
    }
  }

  .ul_cont {
    margin: 0;
    padding: 0;
    height: 28vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #67e0e3;
    }

    .li_item {
      display: flex;
      justify-content: space-around;
      height: 4vh;
      line-height: 4vh;

      span {
        text-align: center;
        display: inline-block;
      }

      .item1 {
        width: 40%;
      }

      .item2,
      .item3 {
        width: 40%;
      }
    }
  }

  .exceed_content:hover {
    cursor: pointer;
  }
}
.el-table {
  /deep/ .el-table__body-wrapper::-webkit-scrollbar {
    width: 7px; /*滚动条宽度*/
    height: 10px; /*滚动条高度*/
  }

  /*定义滚动条轨道 内阴影+圆角*/
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: 0px 1px 3px #071e4a inset; /*滚动条的背景区域的内阴影*/
    border-radius: 10px; /*滚动条的背景区域的圆角*/
    background-color: #071e4a; /*滚动条的背景颜色*/
  }

  /*定义滑块 内阴影+圆角*/
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
    box-shadow: 0px 1px 3px rgb(3, 96, 140) inset; /*滚动条的内阴影*/
    border-radius: 10px; /*滚动条的圆角*/
    background-color: rgb(3, 96, 140); /*滚动条的背景颜色*/
  }
}
</style>
